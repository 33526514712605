// 需求广场模块
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 发布需求
export const publishRequirement = (data) => {
  return request({
    url: '/api/userRequirement/publishRequirement',
    method: "post",
    data
  })
}

// 获取需求列表数据
export const getRequirementPage = (data) => {
  return request({
    url: '/api/userRequirement/getPage',
    method: "post",
    data
  })
}

// 获取需求详情
export const getRequirementDetail = (params) => {
  return request({
    url: '/api/userRequirement/getDetail',
    method: "get",
    params
  })
}