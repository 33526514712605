<template>
  <div class="container requirement_details">
    <div class="requirement_info">
      <div class="user_info">
        <img
          class="avatar"
          mode="widthFix"
          :src="
            details.wxUser.headimgurl ||
            'https://www.zizhihelp.com/upload/zwtx.png'
          "
        />

        <div class="info_wrapper">
          <div class="name_wrapper">
            <div class="name">{{ details.wxUser.nickname }}</div>

            <img
              v-if="details.wxUser.status == '已开通'"
              class="user_type"
              mode="heightFix"
              src="https://www.zizhihelp.com/upload/static/vip.png"
            />

            <img
              v-else
              class="user_type"
              mode="heightFix"
              src="https://www.zizhihelp.com/upload/static/yonghu.png"
            />
          </div>

          <div class="time">{{ details.createTime }}</div>
        </div>
      </div>

      <div class="desc">
        <div class="text">
          {{ details.requirement }}
        </div>
      </div>
    </div>

    <div class="total">
      共为您找到
      <span class="num">{{ total }}</span>
      家符合的企业
    </div>

    <div class="list">
      <div
        class="list_item"
        v-for="(item, index) in list"
        @click="toDetails(item.cid)"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="right_wrapper">
          <div class="company_name">
            {{ item.cname }}
          </div>

          <img
            v-if="item.isRecommended"
            class="icon_recomend"
            src="https://www.zizhihelp.com/upload/static/icon_recomend.png"
          />
        </div>
      </div>

      <el-empty description="暂无数据" v-if="list.length == 0"> </el-empty>
    </div>

    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="paginationTotal"
      @current-change="handleCurrentChange"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 需求广场-详情页 */
import { getRequirementDetail } from "@/api/requirement";
import { combinedQueryList } from "@/api/combinedQuery";

export default {
  data() {
    return {
      wxUser: {}, // 用户信息
      requirementId: "", // 需求ID
      details: { wxUser: {} },
      descShow: false, // 需求描述弹窗显示隐藏
      list: [], // 列表数据
      loading: false, // 加载状态
      // 搜索表单数据
      searchForm: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0, // 总条数
      paginationTotal: 0, // 分页总条数
    };
  },

  methods: {
    /* 获取数据 */
    // 获取详情数据
    getDetails() {
      let params = {
        requirementId: this.requirementId,
      };
      getRequirementDetail(params).then((res) => {
        this.details = res.data;
        let searchForm = JSON.parse(res.data.query);
        this.searchForm = { ...searchForm, pageNum: 1 };
        this.getListData();
      });
    },
    // 获取列表数据
    getListData() {
      this.loading = true;
      combinedQueryList(this.searchForm).then((res) => {
        const { code, data } = res;
        if (code === 0) {
          this.list = data.records;
          this.total = data.total;
          this.paginationTotal = data.total > 2000 ? 2000 : data.total;
          this.loading = false;
        }
      });
    },

    /* 操作 */
    // 跳转企业详情页
    toDetails(id) {
      this.$router.push({
        name: "companyDetails",
        params: { name: "资质等级" },
        query: { id },
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.pageNum = val;
      this.getListData();
    },

    /* 初始化 */
    __init__() {
      this.requirementId = this.$route.params.id;
      this.getDetails();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.requirement_details {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .requirement_info {
    padding: 20px;
    box-sizing: border-box;
    background-color: #f5faff;
    margin-bottom: 24px;

    .user_info {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .info_wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name_wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .name {
            margin-right: 10px;
          }

          .user_type {
            height: 14px;
          }
        }
      }
    }

    .desc {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      color: #666;
      font-size: 14px;
    }
  }

  .total {
    color: #666;
    margin-bottom: 20px;
    font-size: 14px;

    .num {
      font-weight: bold;
      color: #1d89e3;
    }
  }

  .list {
    box-sizing: border-box;

    .list_item {
      display: flex;
      align-items: center;
      padding: 20px 0px;
      box-sizing: border-box;
      border-bottom: 2px solid #f6f6f6;
      font-size: 14px;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .right_wrapper {
        flex: 1;

        .company_name {
          color: #333;
          display: inline;
        }

        .icon_recomend {
          height: 15px;
          margin-left: 10px;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
        .right_wrapper {
          .company_name {
            color: #1d89e3 !important;
          }
        }
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
  }

  .u-empty {
    padding: 50px;
  }
}
</style>
