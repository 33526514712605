// 组合查询模块
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 组合查询列表接口
export const combinedQueryList = (data) => {
  return request({
    url: '/api/CombinedQuery/page',
    method: "post",
    data
  })
}

// 组合查询列表接口-获取总数
export const combinedQueryCount = (data) => {
  return request({
    url: '/api/CombinedQuery/count',
    method: "post",
    data
  })
}
